<template>
    <Fragment>
          <div>
            <v-btn
              :disabled="!(total > 0)"
              class="text-no-style"
              color="primary"
              x-large
              :loading="loadingReporteFondo"
              @click="downloadReporteFondo"
            >
              <v-icon color="white" class="mr-2">mdi-download</v-icon> Descargar reporte
            </v-btn>
          </div>
          <DataTableComponent
              :headers="headers"
              :items="registrosFondos.data"
              :show_loading="registrosFondos.isLoading"
              :total_registros="total"
              v-models:pagina="paginaActual"
              v-models:select="registrosPorPagina"
              @paginar="manejarPaginacionRegistros"
              class="mt-2"
          >
            <template v-slot:[`item.fecha`]="{ item }">
                {{ formatDate(item.fecha, "DD/MM/YYYY") }}
              </template>
              <template v-slot:[`item.monto`]="{ item }">
                ${{ Number(item.monto || 0).toFixed(2)}}
              </template>
              <template v-slot:[`item.acciones`]="{ item }">
                  <div class="d-flex justify-center align-center" style="gap: 8px;">
                    <!-- Ver detalle del registro -->
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn 
                          @click.stop="abrirModalDetalle(item)" 
                          :disabled="registrosFondos.isLoading"
                          v-bind="attrs" 
                          v-on="on" 
                          color="primary" 
                          icon
                        >
                          <v-icon>mdi-eye</v-icon>
                        </v-btn>
                      </template>
                      <span>Ver Detalle</span>
                    </v-tooltip>
                  </div>
              </template>
          </DataTableComponent>
  
          <!-- Modal de detalle -->
          <v-dialog v-model="modalDetalle" persistent max-width="700px">
            <v-card class="pa-4">
              <v-row>
                <!-- corner button -->
                <v-col cols="1">
                  <v-btn
                  :class="$vuetify.breakpoint.lgAndUp ? 'closeBtn' : 'ma-0'"
                  @click="close"
                  icon
                  large
                  >
                  <v-icon v-text="'mdi-close'" color="secondary" />
                  </v-btn>
                </v-col>
                <!-- content -->
                <v-col cols="11" class="mt-4">
                  <div v-if="this.dataDetalle">
                    <v-row class="d-flex flex-column" style="padding-left: 10px; padding-right: 20px;">
                      <div class="d-flex flex-column my-2">
                      <span class="text-caption" v-text="'Número de documento'" />
                      <span v-text="dataDetalle?.numero_documento" />
                      </div>
  
                      <div class="d-flex flex-column my-2">
                      <span class="text-caption" v-text="'Nombre de proveedor'" />
                      <span v-text="dataDetalle?.nombre_proveedor" />
                      </div>
  
                      <div class="d-flex flex-column mb-2">
                        <span class="text-caption" v-text="'Fecha de aplicación'" />
                        <span v-text="moment(dataDetalle?.fecha).format('DD/MM/YYYY')"/>
                      </div>
  
                      <div class="d-flex flex-column mb-2">
                        <span class="text-caption" v-text="'Mes aplicación'" />
                        <span v-text="moment(dataDetalle?.fecha).format('MM/YYYY')"/>
                      </div>
  
                      <div class="d-flex flex-column mb-2">
                        <span class="text-caption" v-text="'Observación'" />
                        <span v-text="dataDetalle?.observacion" />
                      </div>
  
                      <div class="d-flex flex-column mb-2">
                        <span class="text-caption" v-text="'Monto'" />
                        <span v-text="dataDetalle?.monto" />
                      </div>
  
                      <div class="d-flex flex-column mb-2">
                        <span class="text-caption" v-text="'Monto retenido'" />
                        <span v-text="dataDetalle?.monto_retenido" />
                      </div>
  
                      <div class="d-flex flex-column mb-2">
                        <span class="text-caption" v-text="'Nombre del fondo'" />
                        <span v-text="dataDetalle.FondoCompraCentroEscolar?.nombre" />
                      </div>
                      <div class="d-flex flex-column mb-2">
                        <span class="text-caption" v-text="'Centro escolar'" />
                        <span v-text="dataDetalle.FondoCompraCentroEscolar?.Institucion?.nombre" />
                      </div>
                      <div class="d-flex flex-column mb-2">
                        <span class="text-caption" v-text="'Año fiscal'" />
                        <span v-text="dataDetalle.FondoCompraCentroEscolar?.anio_fiscal" />
                      </div>
                    </v-row>
  
                    <!-- button to close -->
                    <v-row class="mb-3 mt-12">
                    <v-btn
                    @click="close"
                    class="secondary--text text-capitalize"
                    text
                    v-text="'volver'"
                    />
                    </v-row>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-dialog>
  
  
          <!-- Confirmacion participacion -->
          <ConfirmationModalComponent 
              :is-open="confirmacionMarcarComoRevisadoAbierta"
              description="¿Desea marcar este fondo como publicado?"
              :isLoading="cambioEstadoDeFondo.isLoading"
              @confirm="marcarRegistroComoRevisado"
              @cancel="confirmacionMarcarComoRevisadoAbierta = false"
          />
          <ConfirmationModalComponent 
              :is-open="confirmacionMarcarComoObservadoAbierta"
              description="¿Desea marcar este fondo como observado?"
              :isLoading="cambioEstadoDeFondo.isLoading"
              @confirm="marcarRegistroComoObservado"
              @cancel="confirmacionMarcarComoObservadoAbierta = false"
          />
          <!-- Confirmacion aprobar todos -->
            <ConfirmationModalComponent 
                :is-open="confirmacionAprobarTodosAbierta"
                description="¿Desea marcar este fondo como observado?"
                :isLoading="informacionFondo.isLoading || aprobacionMasiva.isLoading"
                @confirm="confirmarAprobarTodos"
                @cancel="confirmacionAprobarTodosAbierta = false"
                :show-actions="!informacionFondo.isLoading && registrosPendientesAprobacion > 0"
            >
              <template v-slot:body>
                <template v-if="informacionFondo.isLoading">
                  <v-progress-circular indeterminate :size="35"></v-progress-circular>
                  <p class="mb-0 mt-4">Cargando información del fondo...</p>
                </template>
                <template v-if="!informacionFondo.isLoading && registrosPendientesAprobacion > 0">
                  <v-icon color="#404A7A !important" class="mb-4 feedback-modal-icon">mdi mdi-information</v-icon>
                  <p class="mb-4">
                    ¿Está de acuerdo en publicar  
                    <b>
                      {{ registrosPendientesAprobacion }} 
                      {{ `${registrosPendientesAprobacion > 1 ? 'registros pendientes' : 'registro pendiente'}` }}
                    </b>
                     de revisión? 
                  </p>
                </template>
              </template>
            </ConfirmationModalComponent>
    </Fragment>
  </template>
  <script>
  import { Fragment } from 'vue-fragment';
  import DataTableComponent from '@/components/DataTableComponent.vue';
  import { ConfirmationModalComponent } from '@/components/utils';
  import { formatDate } from '@/utils/datetime';
  import { 
    createPageable, 
    togglePageable, 
    setPageableResponse,
    setLoadableResponse, 
    isResponseSuccesful,  
    createLoadable,
    toggleLoadable,
  } from '@/utils/loadable';
  
  export default {
    name: 'revisionRegistrosFondosCentrosEscolares',
    components: { DataTableComponent, ConfirmationModalComponent, Fragment },
    data: () => ({
        registrosFondos: createPageable([], 10),
        paginaActual: 1,
        registrosPorPagina: 10,
        idFondo: null, 
        //Modal Detalle
        modalDetalle: false,
        dataDetalle: null,
        // Cambio de estado
        confirmacionMarcarComoRevisadoAbierta: false,
        registroActivo: null,
        cambioEstadoDeFondo: createLoadable(null),
        confirmacionMarcarComoObservadoAbierta: false,
        dataFondo: null,
        // Aprobar todo
        informacionFondo: createLoadable({
          revisados: 0,
          pendientes: 0,
          observados: 0,
          total: 0,
        }),
        aprobacionMasiva: createLoadable(null),      
        confirmacionAprobarTodosAbierta: false,
        // catalogo de estados
        filtroEstado: null,
        // Reporte
        loadingReporteFondo: false,
    }),
    computed: {
        total() {
          return this.registrosFondos.pagination.total_rows;
        },
        registrosPendientesAprobacion() {
          return this.informacionFondo.data.pendientes;
        },
        headers() {
          const headers = [
          {
            align: "center",
            text: "Nombre proveedor",
            value: "nombre_proveedor",
            sortable: false,
          },
          {
            align: "center",
            text: "Fecha de documento",
            value: "fecha",
            sortable: false,
          },
          {
            align: "center",
            text: "Observación",
            value: "observacion",
            sortable: false,
          },
          {
            align: "center",
            text: "Monto retenido ($)",
            value: "monto_retenido",
            sortable: false,
          },
          {
            align: "center",
            text: "Monto ($)",
            value: "monto",
            sortable: false,
          },
          {
            align: "center",
            text: "Unidad",
            value: "EmpleadoUnidad.Unidad.nombre",
            sortable: false,
          },
          {
            align: "center",
            text: "Acciones",
            value: "acciones",
            sortable: false,
          }
        ];

        return headers;
      },
    },
    methods: {
        formatDate,
        manejarPaginacionRegistros(paginacion) {
            const { pagina, cantidad_por_pagina } = paginacion;
            this.paginaActual = pagina;
            this.registrosPorPagina = cantidad_por_pagina;
            this.cargarRegistros();
        },
        async cargarRegistros() {
            togglePageable(this.registrosFondos);
            const filtros = {
                pagination: true,
                per_page: this.registrosPorPagina,
                page: this.paginaActual,
                estado: this.filtroEstado,
            };
  
            const { data, headers } = await this.services.ComprasCentroEscolar.getListRegistrosFondo(this.idFondo ,filtros);
            setPageableResponse(this.registrosFondos, data, headers);
        },
        abrirConfirmarFondoComoRevisado(fondo) {
            this.registroActivo = fondo;
            this.confirmacionMarcarComoRevisadoAbierta = true;
        },
        async marcarRegistroComoRevisado() {
            const idRegistro = this.registroActivo.id;
            toggleLoadable(this.cambioEstadoDeFondo);
            const { data } = await this.services.ComprasCentroEscolar.marcarRegistroComoRevisado(idRegistro);
            this.confirmacionMarcarComoRevisadoAbierta = false;
            setLoadableResponse(this.cambioEstadoDeFondo, data, { showAlertOnSuccess: true, skipOnSuccess: true });
            
            if (!isResponseSuccesful(data)) return;
            this.cargarRegistros();
        },
        async marcarRegistroComoObservado() {
          const idRegistro = this.registroActivo.id;
          toggleLoadable(this.cambioEstadoDeFondo);
          const { data } = await this.services.ComprasCentroEscolar.marcarRegistroComoObservado(idRegistro, this.dataFondo);
          this.confirmacionMarcarComoObservadoAbierta = false;
          setLoadableResponse(this.cambioEstadoDeFondo, data, { showAlertOnSuccess: true, skipOnSuccess: true });
          
          if (!isResponseSuccesful(data)) return;
          this.cargarRegistros();
        },
        // Aprobar todos
        async mostrarInformacionFondo() {
          this.confirmacionAprobarTodosAbierta = true;
          toggleLoadable(this.informacionFondo);
          const { data } = await this.services.ComprasCentroEscolar.cargarConteoRegistros(this.idFondo);
  
          if (isResponseSuccesful(data)) {
            const registrosPendientesAprobacion = data.data.pendientes;
  
            if (!(registrosPendientesAprobacion > 0)) {
              this.pushAppMessage({ type: 'warning', message: 'No hay registros pendientes de revisión.' });
              this.confirmacionAprobarTodosAbierta = false;
            }
          }
  
          setLoadableResponse(this.informacionFondo, data);
        },
        async confirmarAprobarTodos() {
          toggleLoadable(this.aprobacionMasiva);
          const { data } = await this.services.ComprasCentroEscolar.aprobarcionMasivaRegistros(this.idFondo);
          this.confirmacionAprobarTodosAbierta = false;
          setLoadableResponse(this.aprobacionMasiva, data, { showAlertOnSuccess: true, skipOnSuccess: true });
          this.cargarRegistros();
        },
        close() {
          this.modalDetalle = false;
          this.dataDetalle = null;
        },
        abrirModalDetalle(registrosFondos) {
          this.modalDetalle = true;
          this.dataDetalle = registrosFondos;
        },
        // Reporte
        async downloadReporteFondo() {
          this.loadingReporteFondo = true;

          const { data, status } = await this.services.ComprasCentroEscolar.getReporteFondo(this.idFondo);

          if (status == 200) {
            const blob = new Blob([data], {
              type: "application/octet-stream",
            });
    
            const file = new File(
              [blob],
              `reporte-fondo${this.moment().format("DD-MM-YYYY")}.xlsx`,
              {
                type: "application/octet-stream",
              }
            );
    
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(file);
            link.download = file.name;
            link.click();
            this.loadingReporteFondo = false;
          }
        },
    },
    watch: {
      filtroEstado() {
        this.paginaActual = 1;
        this.cargarRegistros();
      },
    },
    created() {
        this.idFondo = this.$route.params.id_fondo;
        this.cargarRegistros();
    },
  }
  </script>